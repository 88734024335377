<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Products</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:black;">
                        <i class="fas fa-home"></i> Home<span style="color:black"> >></span>
                    </a>

                </li>
                <li class="breadcrumb-item" style="color:black;">{{ getCategoryName(activeCategoryID) }} >></li>

            </ul>
        </div>
    </nav><br />
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="vertical-pills">
                    <ul class="nav nav-pills flex-column">
                        <li class="nav-item" *ngFor="let item of originalCategory">
                            <a class="nav-link"
                               [routerLink]="['/Products']"
                               [class.active]="activeCategoryID === item.CategoryID"
                               [queryParams]="{ categoryID: item.CategoryID }"
                               (click)="categoryClicked(item.CategoryID)">
                                {{ item.CategoryName }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-9">
                <div class="alphabet-filter">
                    <button *ngFor="let letter of alphabetList"
                            (click)="filterByAlphabet(letter)"
                            [ngClass]="{'active': letter === selectedAlphabet}">
                        {{ letter }}
                    </button>
                </div>


                <!-- Loader -->
                <div class="loader" *ngIf="loading"></div>

                <table class="table" *ngIf="!loading && isGridView">
                    <thead class="thead-light">
                        <tr>
                            <th scope="col">Catalog_No</th>
                            <th scope="col">Chemical Name</th>
                            <th scope="col">Group</th>
                            <th scope="col">CAS No</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let product of paginatedProducts">
                            <tr (click)="navigatingToSearchedProduct(product)" style="cursor:pointer">
                                <td>{{ product.Catalog_No }}</td>
                                <td>{{ product.ProductName }}</td>
                                <td>{{ product.Group }}</td>
                                <td>{{ product.CAS_No }}</td>
                            </tr>
                            <tr *ngIf="selectedProduct && selectedProduct.ProductName === product.ProductName">
                                <td colspan="3">
                                    <table class="table">
                                        <thead class="thead-light">
                                            <tr>
                                                <th scope="col">Mol Formula</th>
                                                <th scope="col">Mol Weight</th>
                                                <th scope="col">Inchi</th>
                                                <th scope="col">Tax</th>
                                                <th scope="col">ISO Smiles</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ selectedProduct.Mol_Formula }}</td>
                                                <td>{{ selectedProduct.Mol_Weight }}</td>
                                                <td>{{ selectedProduct.Inchi }}</td>
                                                <td>{{ selectedProduct.Tax }}</td>
                                                <td>{{ selectedProduct.ISO_Smiles }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <!-- Pagination Controls -->
                <div class="pagination-controls" *ngIf="totalPages > 1 && !loading">
                    <button (click)="changePage(1)" [disabled]="currentPage === 1">First</button>
                    <button (click)="changePage(currentPage - 1)" [disabled]="currentPage === 1">Previous</button>

                    <span>{{ currentPage }} of {{ totalPages }}</span>

                    <button (click)="changePage(currentPage + 1)" [disabled]="currentPage === totalPages">Next</button>
                    <button (click)="changePage(totalPages)" [disabled]="currentPage === totalPages">Last</button>
                </div>
            </div>
        </div>
    </div>

</div>
