import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service'
import { CartService } from '../../Services/cart.service'
declare var $: any;
@Component({
    selector: 'app-proced-to-pay',
    templateUrl: './proced-to-pay.component.html',
    styleUrls: ['./proced-to-pay.component.scss']
})
export class ProcedToPayComponent {
    salesdata: any;
    salesinfo: any;
    totalPrice: any;
    datetime: any;
    OrderDetails: any;
    Id: any;
    deliveryCharge: any;
    offerPrice: any = 0;
    GstPrice: any;
    offerDetails: any;
    offerCode: any;
    WishListitemmsforloginusers: any = [];
    cartIndex: any;
    n: any;
    val: number;
    arr: any = [];
    PaymentType: any;
    addressId: any;
    currentCartItems: any = [];
    customerCartItems: any = [];
    loginStatus: boolean;
    //addressDetails: any;
    addressDetails1: any;
    LoginDetails: any;
    itemSize: any;
    ItemId: any;
    seletedItemforWishlist: any = [];
    wishListItems: any = [];
    subTotal: any = 0;
    HomeURL: any;
    cartItems: any;
    Quantity: any;
    offersdata: any;
    offerId: any;
    alert: any;
    //getAllAdminOrders: any;
    plus: boolean = true;
    TransactionStatus: any;
    admin: any;
    loginDetails: any;
    loginDet: any;
    AID: any;
    orderno: any;
    packageitems: any;
    Packagedata: any;
    packagesarr: any;
    Itemdata: any;
    TTprice: any; TaxnId: any;
    Grandtotal = 0;
    addressDetails: any // Assuming addressDetails is an array
    selectedAddresses: any[] = [];
    address1: any; // Selected billing address
    address2: any; // Selected delivery address
    isBillingAddressSelected: boolean = false;
    isDeliveryAddressSelected: boolean = false;
    useBillingAddress: boolean = false; // Track state of "Use billing address for delivery" checkbox
    useDeliveryAddress: boolean = false;
    isUseBillingAddressCheckboxVisible: boolean = true;
    isUseDeliveryAddressCheckboxVisible: boolean = true;
    token: any;
    zohobook_addressId: any;
    selectaddresstypyid: any;
    billing_address_id: any;
    shipping_address_id: any;
    zohobook_DeliveryaddressId: any;
    zohobook_billing_addressId: any;
    LineItems: any;
    LItems: any;
    lineitems: any;
    LoginDetails1: any;

    constructor(public http: HttpClient, public cartService: CartService,

        public generalService: GeneralService, public router: Router) {
         
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);

       

        this.LineItems = localStorage.getItem("LineItems");
        this.LItems = JSON.parse(this.LineItems)
        this.Packagedata = localStorage.getItem("Packagesarr");
        this.packagesarr = JSON.parse(this.Packagedata)



        this.cartService.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

        })
        this.cartItems.map((a: any) => {    /*this is looping for getiing total amount*/
            this.subTotal += (a.PackPrice * 1) * (a.Quantity)
            // Assuming Price is a number
            const gstTax = this.subTotal * (a.GSTTAX / 100);
            this.Grandtotal = this.subTotal + gstTax
        })
        // Assuming Price is a number

        this.http.get('../assets/WebService.json').subscribe((data: any) => {/* this service call is for getting Homeurl*/
            this.HomeURL = data.Webservice;
        });
    }

    ngOnInit(): void {       
  
            this.getCustomerAddress();      

    }



    GotoAddress() {
        this.router.navigate(['/Useraddress']);
    }




    getCustomerAddress() {
       debugger
        var UploadFile = new FormData();
        UploadFile.append("Param1", this.LoginDetails[0].RegId);
        UploadFile.append("Param2", '1');
        var url = "api/NovusChem/Addressfilterbasedonuid";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.addressDetails = data;
            this.plus = false
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

   






    sendMailToAdmin() {
        debugger

        this.addressDetails1 = this.packagesarr;  // Assuming this holds multiple entries
        let arr = [];

        // Assuming this.LItems and this.addressDetails1 have the same length and map to each other
        for (let i = 0; i < this.addressDetails1.length; i++) {
            arr.push({
                CatalogNo: this.addressDetails1[i].CatalogNo,
                CASNo: this.addressDetails1[i].CAS,
                ChemicalName: this.addressDetails1[i].ProductName,
                Quantity: this.addressDetails1[i].Quantity,
                PackSize: this.LItems[i].PackName,
                Price: this.addressDetails1[i].PackPrice,
                HSNCode: this.addressDetails1[i].HSN,
                UserName: this.LoginDetails[0].UserName,  // Assuming login details are common for all items
                EMailID: this.LoginDetails[0].EMailID,    // Assuming email is common for all items
            });
        }

        var UploadFile = new FormData();
        var url = "api/NovusChem/MailPlaceOrder";

        UploadFile.append("Azhole", JSON.stringify(arr));

        this.generalService.PostData(url, UploadFile).then((data: any) => {
            // Handle the response here
        });
    }






    /*end*/
    PlaceOrder1() {
        debugger
     
            this.arr = [];
            this.n = ""
            var d = new Date();
            this.n = d.getTime();

        for (var i = 0; i < this.cartItems.length; i++) {
            this.arr.push({
                PersonId: this.LoginDetails[0].RegId,
                ProductId: this.cartItems[i].ProductId,
                Quantity: this.cartItems[i].Quantity,
                Address: this.address1,
                AddressDelivery: this.address2,
                CatalogNo: this.cartItems[i].CatalogNo,
                Price: this.cartItems[i].PackPrice,
                PackSize: this.cartItems[i].PackName,
                HSNCode: this.cartItems[i].HSN,
                CASNo: this.cartItems[i].CAS,
                ChemicalName: this.cartItems[i].ProductName,
                GSTTAX: this.cartItems[i].GSTTAX,
                Remarks:this.packagesarr[0].Remarks ? this.packagesarr[0].Remarks : '',
                PackageId: this.cartItems[i].PackageId,
                TotalPrice: localStorage.Grantotal,
                CreatedBy: this.LoginDetails[0].RegID,
                StatusID: 1,
                DisDiscount: this.cartItems[i].DisDiscount
            })
        }
            let formData: FormData = new FormData();
            formData.append('Param', JSON.stringify(this.arr));
            var url = "api/NovusChem/Insert_Customer_Orders";
            this.generalService.PostData(url, formData).then((data: any) => {
                this.orderno = data

                //if (data && data) {
                //    this.generalService.ShowAlert('OrderNo:' + this.orderno, 'Your Order is placed.', 'Success');
                //    this.subTotal = 0
                if (data && data) {
                    this.generalService.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', 'Your order has been placed  Successfully.', '');
                    this.router.navigate(['/MyOrders']);
                    localStorage.removeItem('Packagesarr');
                    localStorage.removeItem('cartItems');
                    localStorage.removeItem('Grantotal');
                    localStorage.removeItem('LineItems');
                    this.cartService.removeAllcart();
                    this.cartItems = {};

                    this.cancel()
                    this.val = 1
                    this.sendMailToAdmin()
                }
            });
    }
    

    Get_Od() {       

        let formData: FormData = new FormData();
        formData.append('Param', this.orderno.Table2[0].MSG);
        var url = "api/NovusChem/Getorderdetails_basedon_OID";
        this.generalService.PostData(url, formData).then((data: any) => {            
            this.salesdata = data         

        });
    }


    cancel() {
        $('#myModal').modal('hide');
    }

    getSelectedbillingAddress(addressId: any, isChecked: boolean, categoryID: number) {
       

        this.selectaddresstypyid = categoryID
        if (isChecked) {
            console.log("Selected Address ID:", addressId);
            if (categoryID === 1) {
                this.address1 = addressId;
                //this.zohobook_billing_addressId = Zoho_Book_AddressID
               // localStorage.setItem("billinaddress", this.zohobook_billing_addressId)
                this.isBillingAddressSelected = true;
            }
        } else {
            if (categoryID === 1 && this.address1 === addressId) {
                this.address1 = null;
                this.isBillingAddressSelected = false;
                //this.zohobook_billing_addressId = Zoho_Book_AddressID
               // localStorage.setItem("billinaddress", this.zohobook_billing_addressId)

            }
        }
    }
    getSelectedDeliveryAddress(addressId: any, isChecked: boolean, categoryID: number,) {
         

        this.selectaddresstypyid = categoryID
        if (isChecked) {
            console.log("Selected Address ID:", addressId);
            if (categoryID === 2) {
                this.address2 = addressId;
                //this.zohobook_DeliveryaddressId = Zoho_Book_AddressID
                //localStorage.setItem("delliveryaddress", this.zohobook_DeliveryaddressId)

                this.isDeliveryAddressSelected = true;
            }
        } else {
            if (categoryID === 2 && this.address2 === addressId) {
                this.address2 = null;
                this.isDeliveryAddressSelected = false;
               // this.zohobook_DeliveryaddressId = Zoho_Book_AddressID
                //localStorage.setItem("delliveryaddress", this.zohobook_DeliveryaddressId)
            }
        }
    }

    useBillingAddressForDelivery(useBillingAddress: boolean) {
       
        if (useBillingAddress && this.isBillingAddressSelected) {
            this.address2 = this.address1;
            this.zohobook_DeliveryaddressId = this.zohobook_billing_addressId
        } else {
            this.address2 = null;
        }

    }

    useDeliveryAddressForBilling(useDeliveryAddress: boolean) {
        
        if (useDeliveryAddress && this.isDeliveryAddressSelected) {
            this.address1 = this.address2;
            this.zohobook_billing_addressId = this.zohobook_DeliveryaddressId
        } else {
            this.address1 = null;
        }
    }

    Sales_orders() {
        




        this.datetime = new Date();
        this.n = this.datetime.toISOString().split('T')[0];

        let formData: FormData = new FormData();
        formData.append('date', this.n);
        formData.append('Per_BOOks_ID', this.loginDetails[0].ZOHO_Books_ID);//change here varient itemid    
        formData.append('shippingCharge', '0');
        formData.append('LineItems', JSON.stringify(this.LItems));
        formData.append('OrderID', this.orderno.Table2[0].MSG);
        formData.append('paymentTermsLabel', 'Net 30');
        formData.append('shipmentDate', this.n);
        formData.append('billing_address_id', this.zohobook_billing_addressId);
        formData.append('shipping_address_id', this.zohobook_DeliveryaddressId);

        var url = "api/Azole/After_Booking_SalesOrder";

        this.generalService.PostData(url, formData).then((data: any) => {
           
            this.salesinfo = data
            if (this.salesinfo.message == "Sales Order has been created.") {
                this.generalService.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', 'Your order has been placed  Successfully.', '');

            }
            this.Get_Od();


        });
    }


    getPaymentType(pType: string) {
        this.PaymentType = pType
    }

    selectmode() {
       
        $('#myModal').modal('show');
    }



    navigatingToProduct(data) {
        var prodName = "";
        var str = data.split(" ")//spliting the product name into string array where space occured
        for (let i = 0; i < str.length; i++) {
            prodName += "_" + str[i];
        }
        this.router.navigate(['/Product/' + prodName]); ///sandhya
    }







}
