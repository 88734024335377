

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
//import { SessiontimeoutService } from './Services/sessiontimeout.service';
import { SwPush } from '@angular/service-worker';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private routerSubscription: any;

    deferredPrompt: any;
    isInstalled = false;
    showModal: boolean;

    constructor(
        private router: Router,
       // private sessionTimeoutService: SessiontimeoutService,
        private swPush: SwPush
    ) { }

    ngOnInit() {
        // Service worker registration, push notifications, etc. (unchanged)

        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            this.deferredPrompt = event;
            this.openModal(); // Show the custom modal
        });

        window.addEventListener('appinstalled', () => {
            console.log('PWA was installed');
            this.closeModal(); // Close the modal if the app is installed
        });

        // Subscribe to router events to handle navigation
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }


    ngOnDestroy() {
        // Unsubscribe from router events to prevent memory leaks
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    openModal() {
        this.showModal = true;
    }

    // Close the modal
    closeModal() {
        this.showModal = false;
    }

    // Trigger the PWA installation
    installPWA() {
        if (this.deferredPrompt) {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                this.deferredPrompt = null;
                this.closeModal(); // Close the modal after action
            });
        }
    }
}
