<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Add to cart</div>

                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">  <i class="fas fa-home"></i>  Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Add to cart</b></li>
            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="container dispnone">
            <div class="row">
                <div class="col-lg-12 col-12 table-responsive">
                    <table id="cart" class="table table-hover table-condensed">
                        <thead>
                            <tr>
                                <th style="width:40%">Product</th>
                                <th style="width:10%">HSN Code</th>
                                <th style="width:10%">Pack Size</th>
                                <th style="width:10%">Price (₹)</th>
                                <th style="width:10%">Tax (%)</th>
                                <th style="width:10%">Qty (Number)</th>
                                <th style="width:22%" class="text-center">Subtotal (₹)</th>
                                <th style="width:10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let items of cartItems;let i=index">
                                <td data-th="Size">
                                    <span style="color: #295fa9;">  {{items.ProductName.replace(' ', '&nbsp;')}}/{{items.CAS}}/{{items.Catalog_No}}</span><br>
                                </td>
                                <td data-th="Size">{{items.HSN}}</td>
                                <td data-th="Size">{{items.PackName}}</td>
                                <td data-th="Price" class="text-center"><span class="gstt ">{{items.PackPrice.toFixed(2)}}</span></td>
                                <td data-th="Price" class="text-center"><span class="pricee ">{{items.GSTTAX}}% </span></td>
                                <td data-th="Quantity">
                                    <div class="btn-group" style="border:1px solid gray;padding:5px" role="group" aria-label="Quantity Buttons">
                                        <span (click)="decreaseQuantity(items)" type="button" class="  quantity-button">-</span>
                                        <span type="button" class="  quantity-button">{{ items.Quantity }}</span>
                                        <span (click)="increaseQuantity(items)" type="button" class="  quantity-button">+</span>
                                    </div>
                                </td>
                                <td data-th="subTotal" class="text-center">
                                    <h6><span class="subtotal"> {{calculateSubTotal(items).toFixed(2)}}</span></h6>
                                </td>
                                <td class="actions" data-th="">
                                    <div (click)="deleteCartfromTable(i,items.PackageId)">
                                        <i class="fa fa-trash" style="color:orangered;cursor:pointer"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td class="dispnone"></td>
                                <td></td>

                                <td class="text-center pricee">Grand&nbsp;Total(₹)</td>
                                <td class="text-right  ">{{Grandtotal.toFixed(2)}}</td>
                                <td class="dispnone"></td>

                            </tr>
                            <tr>
                                <div class="remarks">
                                    <label>Remarks</label>
                                    <input [(ngModel)]="Remarks" name="Remarks" type="text" class="form-control" id="Remarks" placeholder="Enter Remarks">
                                </div>
                            </tr>
                            <tr>
                                <td colspan="5">
                                    <a (click)="navigatae()" class="btn btn-block continshop" style="">
                                        <i class="fa fa-angle-left"></i> Continue Shopping
                                    </a>
                                </td>
                                <td colspan="1" class="dispnone"></td>
                                <td colspan="6">
                                    <a class="btn btn-success btn-block" style="background: #295fa9;" (click)="PlaceOrder()">
                                        Checkout <i class="fa fa-angle-right"></i>
                                    </a>
                                </td>
                            </tr>
                        </tfoot>
                    </table>                                       
                </div>
              
            </div>

        </div>
    </div>



    <div class="container cardnone ">


        <div class="row  m-1">
            <div class="card mt-1 p-3" style="box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;" *ngFor="let items of cartItems;let i=index">
                <div class="row">
                    <div class="col-6">
                        <b>CAS NO <span style="color: #295fa9;"> {{items.CAS}}</span></b>
                        <figure>
                            <img src="{{HomeURL+items.Record_Image}}" class="card_image" alt="DB">
                        </figure>
                        <div class="row">
                            <div class="col-7">
                                <div class="btn-group" style="border:1px solid gray;padding:5px" role="group" aria-label="Quantity Buttons">
                                    <span (click)="decreaseQuantity(items)" type="button" class="  quantity-button">-</span>
                                    <span type="button" class="  quantity-button">{{ items.Quantity }}</span>
                                    <span (click)="increaseQuantity(items)" type="button" class="  quantity-button">+</span>
                                </div>
                                <p></p>
                            </div>
                            <div class="col-3 mt-1">
                                <div (click)="deleteCartfromTable(i,items.PackageId)">
                                    <i class="fa fa-trash" style="color:orangered"></i>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="col-6">
                        <div class="nomargin" style="font-size:11px;color:black">
                            <span class="product-name" style="color: #295fa9;">{{items.ProductName.replace(' ', '&nbsp;')}}</span><br>
                            <span>{{items.HSNCode}}</span><br>
                            <span>{{items.CatalogNo}}</span><br>
                            <span>{{items.PackSize}}</span><br>

                            <p>₹<b>{{items.Price | number:'1.2-2'}}</b> &nbsp;&nbsp; <small> Gst {{items.GSTTAX}}%</small>  </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12  ">
                <p>&nbsp;Grand Total(₹) <b class="grandtotal">{{Grandtotal | number:'1.2-2'}}</b></p>
            </div>
            <div class="col-12 pt-2">
                <a class="btn btn-success btn-block" style="background: #295fa9;" (click)="PlaceOrder()">
                    Checkout <i class="fa fa-angle-right"></i>
                </a>
                <a (click)="navigatae()" class="btn btn-block continshop" style="">
                    <i class="fa fa-angle-left"></i> Continue Shopping
                </a>
            </div>
        </div>


    </div>


</div>
<div class="modal" id="myModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-primary">
                <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Select Delivery mode</h4>



            </div>
            <div class="modal-body">
                <!--<input type="radio" name="mode" (change)="getPaymentType('Online')" /><span>&nbsp;&nbsp;&nbsp;Online payment</span>-->
                <input type="radio" name="mode" (change)="getPaymentType('cashondelivery')" /><span>&nbsp;&nbsp;&nbsp;Cash on delivery</span><br /><br />
                <br />
                <br />
                <div class="modal-footer">

                    <button id="closemodal" type="button" class="btn btn-success" (click)="PlaceOrder()" style="margin-right:46%;">Ok</button>
                    <button id="closemodal" type="button" class="btn btn-danger" (click)="cancel()" style="margin-left:-43%;">Cancel</button>
                </div>

            </div>
        </div>
    </div>
</div>
