import { Component, OnInit, HostListener, Output, EventEmitter, Input, ViewChild, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute, Route, Router, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../../Services/generalservice.service'
import { CartService } from '../../../Services/cart.service'

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements AfterViewInit {
    @ViewChild('loader') loader: ElementRef | undefined;
    HomeURL:any
    SearchKeyWord:any
  arr: any;
    showCategories: boolean = false;
    Categories: any;
    loginDet: any;
    loginDetails: any;
    cartItems: any;
    @Output() someEvent = new EventEmitter<string>();
    cartItemsCount: any;
    category: any;
    searchprods: any;
    token: any;
    searchText: any;
    FlteredProductsList: any;
    loading = false;
    constructor(public cartservice: CartService, private viewportScroller: ViewportScroller, private route: ActivatedRoute,
        public router: Router, public generalService: GeneralService, public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeURL = data.Webservice;
        });
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.cartservice.getProducts().subscribe(res => {/*this is a service call for getting cart items*/
            this.cartItems = res

        })
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // Clear products after navigation is successful
                this.Categories = [];
            }
        });
  }


  ngOnInit(): void {
    this.GetCategories();
  }
  classApplied = false;
  //  router: any;
    selectedItem: any;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }
  listClick(val:any) {
    this.selectedItem = val;
  }

  isActive(route: string): boolean {
    return this.router.isActive(route, true);
  }
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

    ngAfterViewInit() {
        // Ensure loader is defined before using it
        if (this.loader) {
            this.hideLoader(); // Initially hide the loader
        } else {
            console.error('Loader element not found.');
        }
    }

   

    FilterProducts(event: any) {
        debugger
        const formData = new FormData();
        formData.append('Param', event.query);
        const url = 'api/NovusChem/NC_Get_SearchResults';
        this.generalService.PostData(url, formData).then(
            (data) => {
                this.category = data;

                this.FlteredProductsList = this.category.filter(product =>
                    product.ProductName.toLowerCase().includes(event.query)
                );
            },
            (err) => {
               
            }
        );

    }


  

    GoToProductsGrid(Value) {

     
        {
            if (Value == undefined) {
                localStorage.removeItem('Value');
                this.router.navigateByUrl('/Products');
              
            }
            else {
                debugger
                let query = Value.ProductName;
              
               
                localStorage.removeItem('Value');
                localStorage.setItem('Value', query)
               // this.router.navigateByUrl('/Searchproduct/', );
                this.router.navigate(['/Searchproduct', Value]);
            }

        }

    }

    GoToProductsGridWithSearchClick(Value) {
        debugger
      
        {
            if (Value == undefined) {
                localStorage.removeItem('Value');
                this.router.navigateByUrl('/Searchproduct');
            }
            else {
                localStorage.removeItem('Value');
                localStorage.setItem('Value', Value)
                this.router.navigate(['/Searchproduct', Value]);
            }

        }

    }



    // Methods to show and hide the loader
    showLoader() {
        if (this.loader) {
            this.loader.nativeElement.style.display = 'block';
        } else {
            console.error('Loader element not found.');
        }
    }

    hideLoader() {
        if (this.loader) {
            this.loader.nativeElement.style.display = 'none';
        } else {
            console.error('Loader element not found.');
        }
    }

  public onClick(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }
  Schools() {
    //this.router.navigate(['/Schools']);
  } home() {
    this.router.navigate(['/Home']);
  } about() {
      this.router.navigate(['/About']);
  } contact() {
    this.router.navigate(['/Contact']);
  }
  Login() {
    this.router.navigate(['/Login']);
  }
    public logout(): void {
        localStorage.setItem('previousUrl', this.router.url); // Store the current URL before logging out
        this.clearUserSession(); // Implement session clearing logic
        this.router.navigate(['/Login']).then(() => {
            window.location.reload();
        });// Navigate to login page after logout
    }

    private clearUserSession(): void {
       
        // Implement session clearing logic here
        localStorage.removeItem('LoginDetails');
        
        // Any other session management or cleanup needed
    }

  Request() {
    this.router.navigate(['/Request']);
    }

    getProducts1(searchValue: string) {
        if (!searchValue) {
            this.category = null; // Clear the category if searchValue is empty
            return; // Do nothing if searchValue is empty
        }

        const formData = new FormData();
        formData.append('Param1', searchValue);

        const url = 'api/NovusChem/Products_Search';
        this.generalService.PostData(url, formData).then(
            (data) => {
              
                this.category= data;
            },
            (err) => {
              //  this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
            }
        );
    }
   
    SearchProducts(event:any) { 
        debugger
        const formData = new FormData();
        formData.append('Param', event);

        const url = 'api/NovusChem/Get_Products_basedon_SearchProd';
        this.generalService.PostData(url, formData).then(
            (data) => {
                debugger
                this.searchprods = data;
                this.loading = false; // Hide loader after search completes successfully
            },
            (err) => {
                // Handle error and hide loader
                this.loading = false;
                // this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
            }
        );
    }
    
    GetCategories() {
      
        var url = "api/Token/Gettoken";
        this.generalService.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({

            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeURL + "api/NovusChem/NC_Category_Crud";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(
                (data: any) => { 

                    this.Categories = data;
                
                },
                (err) => {
                   // this.generalService.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

    cartdedails() {
       
        if (!this.loginDetails) {
            this.generalService.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'You are not logged in. Please login', 'Warning');

            this.router.navigate(['/Login']);

        }
        else if (this.cartItems === undefined || !this.cartItems || this.cartItems.length === 0) {
            this.generalService.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'No Products in the Cart', '');
        } else {
            this.router.navigate(['/Cart']);
        }
    }

    countReturn() {


        this.cartservice.getProducts().subscribe(res => {/*getting cart product list count from cartservice*/

            this.cartItemsCount = res.length
            return this.cartItemsCount
        })
        return this.cartItemsCount
    }
    //navigatingToSearchedProduct(CategoryID: number) { 
    //    this.router.navigate(['/Products/' + CategoryID]);
    //}
   

    navigatingToSearchedProduct(pro: any) {
        debugger
        const cleanedProductName = pro.ProductName
            .replace(/[(),%+\-\s]/g, '')
        localStorage.setItem('ProName', pro.ProductName)
        const url = `${pro.CategoryName}-${pro.CAS_No}-${cleanedProductName}`;
        this.router.navigate(['/Product/', url]).then(() => {
            // Reload the page
            window.location.reload();
        });
      
    }


    searchresults(text: any) {
        debugger;
        this.router.navigate(['/Searchproduct/', text]).then(() => {
            // Reload the page
            window.location.reload();
        });
    }

    // Method to show and hide the loader
    searchAndClear() {
        this.searchresults(this.SearchKeyWord);
        this.SearchKeyWord = ''; // Clear the search input after search
    }




    navigatingToSearchedProducts(CategoryID: number, CategoryName: any) {
        debugger
        this.loading = true;  // Show the loader
        this.router.navigate(['/Products'], { queryParams: { categoryID: CategoryID, CategoryName: CategoryName } });
        this.loading = false;
        // const queryParams = this.route.snapshot.queryParams;
        localStorage.setItem("cat", CategoryName);

    }


    getProducts(searchValue: string) {
        if (!searchValue) {
            this.category = []; // Clear the Categories array if searchValue is empty
            return; // Do nothing if searchValue is empty
        }

        this.loading = true; // Show loader when search starts

        const formData = new FormData();
        formData.append('Param', searchValue);

        const url = 'api/NovusChem/All_Products_Search';
        this.generalService.PostData(url, formData).then(
            (data) => {
                this.category = data;
                this.loading = false; // Hide loader after search completes successfully
            },
            (err) => {
                // Handle error and hide loader
                this.loading = false;
                // this.generalService.ShowAlert('ERROR', 'Something went wrong', 'error');
            }
        );
    }


}
