import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from '../../Services/generalservice.service';
import { Router, ActivatedRoute } from '@angular/router'

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
    public form: FormGroup;
    arr: any = [];
    passwordshow: boolean = false;
    Mobilenum: any; checkNo: any;
    mobiledata: any;
    HomeUrl: any;
    token: any;
    Category: any;
    BranchdropList: any;
    OrganisationList: any;
    SalutationList: any;
    enableGSTNoField: any;
    Organisations: any;
    confirmPasswordShow: boolean = false;
    togglePasswordVisibility(field: string) {
        if (field === 'password') {
            this.passwordshow = !this.passwordshow;
        } else if (field === 'confirmPassword') {
            this.confirmPasswordShow = !this.confirmPasswordShow;
        }
    }

    constructor(public generalservice: GeneralService, public fb: FormBuilder, public http: HttpClient,
        private router: Router) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            //this.HomeUrl = 'http://localhost:57391/'
         

        });

        this.form = this.fb.group({
           
            UserName: ['', Validators.required],
            LastName: ['', Validators.required],
            Salutation: ['', Validators.required],
            Organisation: ['', Validators.required],
            EMailID: ['', Validators.required],
            Phone1: ['', Validators.required],
            City: ['', Validators.required],
            PWD: ['', Validators.required],
            Confirmpassword: ['', Validators.required],
            GSTNo: ['',],
            CompanyName: ['', Validators.required],
        })

    }

    ngOnInit() {
        this.GetSalutation();
        this.GetOrganisation();
    }

    filterGroupsSingle4(event: any) {
        // Your existing autocomplete filter logic goes here
        let filtered: any = [];
        let query = event.query;
        {
            this.OrganisationList = [];
            for (let i = 0; i < this.Organisations.length; i++) {
                let type = this.Organisations[i];
                if (type.Organisation.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                }
            }
            this.OrganisationList = filtered;
        }

        // Update GSTID when a value is selected from the autocomplete dropdown

    }

    filterGroupsSingle1(event: any) {
        // Your existing autocomplete filter logic goes here
        let filtered: any = [];
        let query = event.query;
        {
            this.SalutationList = [];
            for (let i = 0; i < this.Category.length; i++) {
                let type = this.Category[i];
                if (type.Salutation.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                }
            }
            this.SalutationList = filtered;
        }

        // Update GSTID when a value is selected from the autocomplete dropdown

    }
   
    onSubmit() { 
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            if (
              
                !this.form.value.UserName ||
                !this.form.value.EMailID ||
                !this.form.value.Phone1 ||
                !this.form.value.City ||
                !this.form.value.LastName ||
                !this.form.value.Salutation ||
                !this.form.value.Organisation ||
                !this.form.value.CompanyName ||
                !this.form.value.PWD 
              
            ) {
                this.generalservice.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Please fill in all fields', '');
                return;
            }

            if (this.checkNo === 10) {
                this.generalservice.ShowAlert('<img src = "../../../assets/icons/icons8-warning.gif" />', 'Mobile number already exists.', '');
                return;
            } 
            this.arr = [];
            this.arr.push({
               
                UserName: this.form.value.UserName,
                LastName: this.form.value.LastName,
                SId: this.form.value.Salutation.SId,
                OId: this.form.value.Organisation.OId,
                CompanyName: this.form.value.CompanyName,
                EMailID: this.form.value.EMailID,
                Phone1: this.form.value.Phone1,
                City: this.form.value.City,
                PWD: this.form.value.PWD,                
                GSTNo: this.form.value.GSTNo,
                RoleId: 2,
                Status:true
                
            });

            var uploadfile = new FormData;
            uploadfile.append("Param", JSON.stringify(this.arr));
            uploadfile.append("Flag", "1");

            //var url = "api/NovusChem/NC_Registation_GAUD";

            var url = this.HomeUrl + "api/NovusChem/NC_Registation_GAUD";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, uploadfile, { headers }).subscribe(
                (data: any) => { 

                    if (data === "Mobile Exists") {
                        this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-warning.gif" />', 'Mobile Number Already Exists', '');
                    } else if (data != "") {
                        this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Your registration completed successfully', '');
                        localStorage.setItem("LoginDetails", JSON.stringify(this.arr));
                      
                        var uploadfile = new FormData();
                        uploadfile.append("Email", JSON.stringify(this.arr));
                        var url = "api/NovusChem/RegistrationMailTo_Admin";
                        this.generalservice.PostData(url, uploadfile).then(data => {
                            
                            var msg = data;
                            this.generalservice.ShowAlert('<img src="../../../assets/icons/icons8-success.gif" />', 'Registration completed successfully and , We have received your request for sign-up, and your account will be activated shortly. If you need any assistance in the meantime, feel free to contact us', 'Success');
                            localStorage.setItem("LoginDetails", JSON.stringify(this.arr));
                            //this.router.navigate(['/']).then(() => {
                             // window.location.reload();
                           // });
                            this.router.navigate(['/Login']).then(() => {
                              //  window.location.reload();
                            });
                        });
                       
                    }

                },
                (err) => {
                    //this.generalservice.ShowAlert(' < img src = "../../../assets/icons/icons8-cancel.gif" />', 'Something went wrong, please try again later', '< img src = "../../../assets/icons/icons8-cancel.gif" />');
                });
        });
    }

    /*check mobile exist or not*/
    CheckMobileNo() {
        debugger
        this.Mobilenum = this.form.get('Phone1').value;
        var UploadFile = new FormData();
        var UploadFile = new FormData();
        UploadFile.append("Param", '1');
        UploadFile.append("Param1", this.Mobilenum);
        var url = "api/NovusChem/Get_Check_Mobile_Email"
        this.generalservice.PostData(url, UploadFile).then(data => {
            this.mobiledata = data; 
            if (data == 'Mobile Exists') {
                this.checkNo = 10;
            }
            else {
                this.checkNo = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
    /*endregion*/

    GetSalutation() {

        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({
              


            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeUrl + "api/NovusChem/NC_Salutation_crud";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {

                this.Category = data;



            },
                err => {
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

    GetOrganisation() {

        // Call Gettoken and await its completion
        var url = "api/Token/Gettoken";
        this.generalservice.GetData(url).then((data: any) => {

            if (data && data.access_token) {
                this.token = data.access_token;
            }
            this.arr = [];
            this.arr.push({



            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = this.HomeUrl + "api/NovusChem/NC_Organisation_crud";
            var accessToken = this.token; // Use the token obtained from Gettoken

            // Set the Authorization header with the access token
            const headers = new HttpHeaders({
                'Authorization': `Bearer ${accessToken}`// Prefix "Bearer" is a common convention for JWT tokens
            });

            // Use HttpHeaders in the request
            this.http.post(url, UploadFile, { headers }).subscribe(data => {

                this.Organisations = data;



            },
                err => {
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        });
    }

}

