import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpHeaders } from '@angular/common/http';

import { NgxCaptureService } from 'ngx-capture';
import { ViewportScroller } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-ledgerreports',
    templateUrl: './ledgerreports.component.html',
    styleUrls: ['./ledgerreports.component.scss']
})
export class LedgerreportsComponent {
    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';

    HomeUrl: any;
    selectedFile: File;
    imagefile: string | ArrayBuffer;
    image: any;

    constructor(private captureService: NgxCaptureService, private viewportScroller: ViewportScroller, public generalService: GeneralService, private http: HttpClient, public fb: FormsModule, public Rm: ReactiveFormsModule) {


    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);

    }

    ngOnInit() {


    }

    CompanyName = '';
    ProfileDescription= '';
            Address = '';
             State = '';
             City = '';
             SalesContact = '';
            Phone = '';
             EMailID = '';
             Pin = '';
             WebsiteAddress = '';
             ProductsYouProvide = '';
             UploadProductList = '';
    Remarks = '';
    


    onSubmit(form: any) {
        debugger;
        this.EnquiryAry = [];
        this.EnquiryAry.push({
            CompanyName: form.CompanyName,
            ProfileDescription: form.ProfileDescription,
            Address: form.Address,
            State: form.State,
            City: form.City,
            SalesContact: form.SalesContact,
            ProductsYouProvide: form.ProductsYouProvide,
            Phone: form.Phone,
            EMailID: form.EMailID,
            Pin: form.Pin,
            UploadProductList: this.imagefile
        });

        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.EnquiryAry));
        UploadFile.append("Flag", '1');

        var url = "api/NovusChem/NC_BecomASupplier_crud";
        this.generalService.PostData(url, UploadFile).then((data: any) => {

            Swal.fire(
                'SUCCESS',
                'Your request was submitted successfully',
                'success'
            ).then(() => {
                // Reload the page after success message
                window.location.reload();
            });

            // Clear the form fields
            form.reset(); // This resets the form
            this.imagefile = null; // Clear the image file

        });
    }

    detectFiles(event: any) {
        const input = event.target;
        if (input.files.length) {
            const file = input.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.imagefile = reader.result;
                this.image = this.imagefile;
            };
            reader.readAsDataURL(file);

            const idxDot = file.name.lastIndexOf(".") + 1;
            const extFile = file.name.substr(idxDot, file.name.length).toLowerCase();

            if (['jpg', 'jpeg', 'png', 'doc', 'docx', 'pdf', 'xls', 'xlsx'].includes(extFile)) {
                const uploadFile = new FormData();
                uploadFile.append("PostedFile", file);
                const url = 'api/NovusChem/NC_Upload_Excel';

                this.generalService.PostData(url, uploadFile).then((data: any) => {
                    if (data != null) {
                        this.imagefile = data;
                        this.image = data;
                    }
                }).catch(error => {
                    console.error("Upload failed:", error);
                });
            } else {
                alert("Only doc, docx, jpg, jpeg, png, pdf, xls, and xlsx files are allowed!");
            }
        }
    }

    onFileSelected(event: any) {
        this.selectedFile = event.target.files[0] as File;
    }
    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var subject = form.subject;
        console.log(subject);

        var selectedJobRole = form.selectedJobRole;
        console.log(selectedJobRole);
        var todayDate = form.todayDate;
        console.log(todayDate);
        var message = form.message;
        console.log(message);
    }
  

}
