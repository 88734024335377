<div class="topnav">
    <div class="container-fluid" style="">
        <div class="row">
            <div class="col-lg-4 col-3 topnav1" routerLink="/Home">
                <img src="../../../../assets/Images/logo1.png" alt="NovusChem logo"class="NovusChem" />
            </div>
            <div class="col-lg-5 col-9">
                <div class="input-group">
                    <div class="search-container">
                        <!-- Hidden input fields to prevent autofill -->
                        <input type="text" style="display:none" autocomplete="username">
                        <input type="password" style="display:none" autocomplete="new-password">

                        <input type="text"
                               placeholder="Search by Chemical Name/ CAS No/Catalog No/Category"
                               #searchInput
                               (input)="SearchProducts(searchInput.value)"
                               (keydown.enter)="searchAndClear()"
                               name="random_search_name"
                               [(ngModel)]="SearchKeyWord"
                               autocomplete="off" />

                        <button type="submit" (click)="searchAndClear()">Search</button>
                    </div>
                </div>


                <div class="search-results-popup" *ngIf="searchInput.value && searchprods !== null">
                    <div class="loader" *ngIf="loading"></div>
                    <div class="card" *ngIf="searchprods && searchprods.length > 0" style="cursor:pointer">
                        <div>
                            <ul class="list-group search-results-container">
                                <li class="list-group-item" *ngFor="let item of searchprods | slice:0:7"
                                    (click)="navigatingToSearchedProduct(item)" scrollHeight="50px">{{item.ProductName}}|{{item.Catalog_No}}|{{item.CAS_No}}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="no-results" style="margin-top:5px" *ngIf="searchprods && searchprods.length === 0">
                        No products matched
                    </div>
                </div>
            </div>

            <div class="col-lg-2 col-12 topnav1">
                <!--<div class="text-end fs-12" style="color: #331515 " *ngIf="!this.loginDetails!=undefined">
            sign in
        </div>-->

                <div class="text-end" *ngIf="this.loginDetails==undefined">
                    <span class="fs-13" (click)="logout()" > Sign in</span>
                </div>

                <div class="text-end fs-12" style="color: #331515 " *ngIf="this.loginDetails!=undefined">

                    <div class="dropdown">
                        <p class="dropbtn" style="color: black;">
                            {{loginDetails[0]?.UserName}}
                        </p>
                        <div class="dropdown-content">
                            <a routerLink="/Profile">My Profile</a>
                            <a routerLink="/Address">Address</a>

                            <a routerLink="/MyOrders">My Orders</a>

                            <a *ngIf="this.loginDetails!=undefined" (click)="logout()">Logout</a>
                        </div>
                    </div>

                </div>


            </div>
            <div class="col-lg-1 col-1">
                <span class="nav-link txt-end" (click)="cartdedails()">
                    <i class="fa-solid fa-cart-shopping" aria-hidden="true"></i>({{countReturn()}})
                </span>
            </div>

        </div>
        </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light "
     [class.active]="classApplied"style="background-color: #d03737">
    <div class="container">
        <div class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </div>
        <div class="brand-name" (click)="home()" >
            <img src="../../../../assets/Images/Whitelogo.png"  class="" alt="Novus ChemStrength Logo" />
        </div>
        
        <div class="collapse navbar-collapse" id="navbarSupportedContent"  >
            <ul class="navbar-nav">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item" routerLink="/About"><span class="nav-link">About</span></li>
                <li class="nav-item">
                    <div class="dropdown">
                        <div class="dropbtn" (mouseenter)="GetCategories()" style="cursor:pointer">
                            Products &nbsp;<i class="fa fa-caret-down"></i>
                            <div class="dropdown-content">
                                <div *ngFor="let link of Categories;">
                                    <a [routerLink]="['/products']" [queryParams]="{ categoryID: link.CategoryID, categoryName: link.CategoryName }" (click)="navigatingToSearchedProducts(link.CategoryID, link.CategoryName)">
                                        {{ link.CategoryName }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </li>
                <li class="nav-item" routerLink="/RequestForQuote"><span class="nav-link">Request Products</span></li>
                <li class="nav-item" routerLink="/HowToOrder"><span class="nav-link">How to Order</span></li>
                <li class="nav-item" routerLink="/SearchCOA"><span class="nav-link">COA Search</span></li>
                <li class="nav-item" routerLink="/Supplier"><span class="nav-link">Become A Supplier</span></li>
 
                <li class="nav-item" (click)="contact()"><span class="nav-link">Contact</span></li>
          
              
              
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item" routerLink="/About">
                    <span class="nav-link">About</span>
                   
                </li>
                <li class="nav-item">
                    <div class="dropdown">
                        <div class="dropbtn" (mouseenter)="GetCategories()" style="cursor:pointer">
                            Products &nbsp;<i class="fa fa-caret-down"></i>
                            <div class="dropdown-content">
                                <div *ngFor="let link of Categories;">
                                    <a [routerLink]="['/products']" [queryParams]="{ categoryID: link.CategoryID, categoryName: link.CategoryName }" (click)="navigatingToSearchedProducts(link.CategoryID, link.CategoryName)">
                                        {{ link.CategoryName }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="nav-item" routerLink="/RequestForQuote"><span class="nav-link">Request Products</span></li>
                <li class="nav-item" routerLink="/HowToOrder"><span class="nav-link">How to Order</span></li>
                <li class="nav-item" routerLink="/SearchCOA"><span class="nav-link">COA Search</span></li>
                <li class="nav-item" routerLink="/Supplier"><span class="nav-link">Become A Supplier</span></li>
            
                <li class="nav-item" (click)="contact()"><span class="nav-link">Contact</span></li>
                <li class="nav" *ngIf="this.loginDetails==undefined">
                    <div class="nav-item">
                        <span class="nav-link" (click)="Login()">Login</span>
                    </div>
                </li>
             
                <li class="nav-item" *ngIf="this.loginDetails!=undefined">
                    <div class="dropdown">
                        {{ loginDetails[0]?.UserName}}
                        <p class="dropbtn circle" style="text-align:center;height:40px;color:#0c1248">

                        </p>
                        <div class="dropdown-content" >

                            <a routerLink="/Address">Address</a>

                            <a routerLink="/MyOrders">My Orders</a>
                            <a routerLink="/Favourite">My Favrotes</a>
                            <a *ngIf="this.loginDetails!=undefined" (click)="logout()">Logout</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
