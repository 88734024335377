
<div class="modal" id="installModal" *ngIf="showModal">
    <div class="modal-content">
        <span class="close-btn" (click)="closeModal()">&times;</span>
        <div class="modal-body">
            <div class="modal-icon">
            
                <img src="../assets/icons/favicon.ico" alt="Install Icon" />
            </div>
            <div class="modal-message">
                <p>Install this app on your desktop?</p>
            </div>
            <div class="modal-buttons">
                <button (click)="installPWA()">Install</button>
                <button (click)="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</div>

<app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>
